/* eslint-disable */
import {
  TextInput,
  Typography,
  Message,
  Button,
  Tabs,
  Input,
  FloatButton,
} from '../../../utils/antd-components/index.tsx';
import { Select } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { signInStart, signInStop } from '../../../redux/user/userSlice.js';
import { useDispatch } from 'react-redux';
import { ApiUtils } from '../../../utils/api.tsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useLanguageContext } from '../../../utils/context/LanguageContext.tsx';

export default function DashSettings() {
  const authUser = useAuthUser();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { LANGUAGELIST, onClickLanguageChange, i18n } = useLanguageContext();
  const { Option } = Select;
  const userId = authUser._id;
  const { loading } = useSelector((state) => state.user);
  const [updateUserSuccess, setUpdateUserSuccess] = useState(null);
  const [updateUserError, setUpdateUserError] = useState(null);
  const [formData, setFormData] = useState({
    oldpassword: '',
    newpassword: '',
    confirmpassword: '',
  });
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e);
    onClickLanguageChange(e);
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setUpdateUserError(null);
    setUpdateUserSuccess(null);
    const regex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?]).{6,30}$',
    );
    if (Object.keys(formData).length === 0) {
      setUpdateUserError(t('no-changes-made'));
      return;
    } else if (
      !formData.oldpassword ||
      !formData.newpassword ||
      !formData.confirmpassword
    ) {
      setUpdateUserError(t('please-fill-all-fields'));
      return;
    } else if (formData.newpassword !== formData.confirmpassword) {
      setUpdateUserError(t('new-password-and-confirm-password-must-match'));
      return;
    } else if (!regex.test(formData.newpassword)) {
      setUpdateUserError(t('password-validation-failed'));
      return;
    }
    try {
      dispatch(signInStart());
      // const data = await doUpdatePassword(formData.newpassword);
      // if (data) {
      //   setUpdateUserSuccess(t('password-updated-successfully'));
      //   return dispatch(signInStop());
      // } else {
      //   setUpdateUserError(t('error-pls-try-again'));
      //   return dispatch(signInStop());
      // }
    } catch (error) {
      console.log('error n change pass=====', error);
      setUpdateUserError(t('error-pls-try-again'));
      return dispatch(signInStop());
    }
  };
  const items = [
    {
      key: '1',
      label: t('changePassword'),
      children: (
        <div className="flex flex-col gap-2">
          <div className="w-full flex flex-col gap-2 mt-3">
            <Typography value={t('oldpassword')} />

            <TextInput
              type="text"
              id="oldpassword"
              placeholder={t('newPassword')}
              onChange={handleChange}
            />
          </div>
          <div className="w-full flex flex-col gap-2 mt-3">
            <Typography value={t('newPassword')} />
            <Input
              placeholder={t('newPassword')}
              id="newpassword"
              onChange={handleChange}
            />
          </div>
          <div className="w-full flex flex-col gap-2 mt-3">
            <Typography value={t('Confirm Password')} />
            <Input
              placeholder={t('Confirm Password')}
              id="confirmpassword"
              onChange={handleChange}
            />
          </div>
          <div className="w-full flex flex-col gap-2 mt-5">
            <Button
              type="primary"
              onClick={() => handleSubmit(console.log('update clicked'))}
              disabled={loading}
            >
              {loading ? 'Loading...' : t('update')}
            </Button>
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: t('change-language'),
      children: (
        <Select
          required={false}
          onChange={handleLanguageChange}
          value={selectedLanguage}
          placeholder={t('select')}
        >
          {LANGUAGELIST &&
            Object.keys(LANGUAGELIST).map((key) => (
              <Option key={key} value={key}>
                {LANGUAGELIST[key].nativeName}
              </Option>
            ))}
        </Select>
      ),
    },
    {
      key: '3',
      label: t('account'),
      children: t('account'),
    },
    {
      key: '5',
      label: t('privacy'),
      children: t('privacy'),
    },
  ];
  return (
    <div className="p-3 md:mx-auto">
      <FloatButton.BackTop />
      <Tabs defaultActiveKey="1" items={items} />
      {updateUserError && (
        <Message
          className="mt-5"
          type="error"
          successMessage={updateUserError}
        />
      )}
      {updateUserSuccess && (
        <Message successMessage={updateUserSuccess} type="success" />
      )}
    </div>
  );
}
